<template lang="pug">
el-drawer(
  title="活動預約詳細資訊"
  :visible.sync="drawer"
  direction="rtl"
  :before-close="handleClose"
  :append-to-body="appendToBody"
  :wrapperClosable="false")
  .drawer.flex.column.align-stretch.gap-10
    .col.flex.column.align-stretch.gap-4(
      v-for="col in columns", :key="col.key"
      v-if="showData[col.key] || col.defaultValue !== ''"
    )
      .name {{ col.text }}
      .value.flex.align-center
        span(v-if="showData[col.key]") {{ showData[col.key] }}
        span(v-else) {{ col.defaultValue }}
</template>

<script>
const receiveByMsg = {
  organization: '機構',
  professional: '專業人士',
  paid_by_organziation: '機構自籌',
};
const locationMsg = {
  inhouse: '館內活動',
  virtual: '線上活動',
  otherplace: '外展活動',
};
export default {
  name: 'activity-viewer',
  props: {
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: false,
      errOld: false,
      columns: [
        { text: '活動名稱', key: 'name', defaultValue: '--' },
        { text: '單位', key: 'organization', defaultValue: '--' },
        { text: '對象描述', key: 'targetNote', defaultValue: '--' },
        { text: '鐘點總計', key: 'price', defaultValue: '--' },
        // { text: '酬勞', key: 'prize', defaultValue: '--' },
        { text: '入帳方式', key: 'paymentReceivedBy', defaultValue: '--' },
        { text: '活動時間', key: 'time', defaultValue: '--' },
        { text: '活動類型', key: 'locationType', defaultValue: '--' },
        { text: '場地名稱', key: 'location', defaultValue: '' },
        { text: '視訊連結', key: 'remoteLink', defaultValue: '' },
        { text: '雜費', key: 'incidentalExpense', defaultValue: '--' },
        { text: '交通費', key: 'transportationFare', defaultValue: '--' },
      ],
      showData: {},
    };
  },
  methods: {
    handleClose(done) {
      done();
    },
    close(result) {
      this.$emit('finish', result);
      this.drawer = false;
    },
    show(data) {
      console.log(data);
      this.drawer = true;
      let note = {};
      if (data.description) {
        note = JSON.parse(data.description);
      }
      let location = '';
      if (data.location_type === 'inhouse') {
        location = `${data.room.branch.name} ${data.room.name}`;
      } else if (data.location_type === 'otherplace') {
        location = `${data.county} ${data.district} ${data.address}`;
      }

      const length = ((data.end_at - data.start_at) / 3600).toFixed(1);
      const time = `${this.$timestampToDateTimeMinuteWithDay(data.start_at)} (${length}) hr`;

      this.showData = {
        name: data.name,
        organization: data.organization,
        targetNote: note[0] || '',
        price: data.price,
        paymentReceivedBy: receiveByMsg[data.payment_received_by] || '',
        time,
        locationType: locationMsg[data.location_type] || '',
        location,
        remoteLink: data.location_type === 'virtual' ? data.meeting_link : '',
        incidentalExpense: data.incidental_expense || '',
        transportationFare: data.transportation_fare || '',
        origData: data,
      };
    },
    reset() {
      this.drawer = false;
      this.errOld = false;
    },
  },
  mounted() {
    this.$on('show', (data) => {
      this.show(data);
    });
  },
};
</script>

<style lang="scss" scoped>
.drawer {
  padding: 20px;
  padding-top: 0;
}
</style>
