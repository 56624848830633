<template lang="pug">
.activity-page.flex.column.gap-20
  .search-bar.flex.align-center.justify-space-between
    .filter.flex.align-end.gap-10
      .column.flex.column.align-start.gap-4
        .title 預約時間
        el-date-picker.date-range-input(
          @change="changeDate"
          v-model="times"
          type="daterange"
          range-separator="至")
  template(v-if="activities.length > 0")
    .table.flex
      el-table(:data="showActivities", @row-click="showActivity")
        el-table-column(label="活動名稱", prop="name")
        el-table-column(label="日期")
          template(slot-scope="scope")
            span {{ $timestampToDate(scope.row.start_at) }}
        el-table-column(label="時間")
          template(slot-scope="scope")
            .flex.align-center.gap-6
              span {{ $timestampToTime(scope.row.start_at) }}
              span -
              span {{ $timestampToTime(scope.row.end_at) }}
        el-table-column(label="場地")
          template(slot-scope="scope")
            template(v-if="inhouse")
              span {{ scope.row.room.branch.name }}
              span &nbsp;{{ scope.row.room.name }}
            template(v-else="location_type")
              .clickable.link-text(@click="$goLink(scope.row.meeting_link)") 線上活動
        el-table-column(label="酬勞")
          template(slot-scope="scope")
            span NT $ {{ scope.row.professional_margin }}
        el-table-column(label="入帳方式")
          template(slot-scope="scope")
            template(v-if="scope.row.payment_received_by == 'organization'")
              span 機構
            template(v-if="scope.row.payment_received_by == 'professional'")
              span 專業人士
    .pagination.flex.align-center.justify-end
      el-pagination(
        layout="total, sizes, prev, pager, next, ->, jumper"
        :page-size.sync="limit"
        :current-page.sync="currentPage"
        :total="showedTotal"
      )
  .empty(v-else) 此篩選無任何活動記錄
  activity-viewer(
    ref="activityViewer"
  )
</template>

<script>
import { getActivities } from '@/api/activity';
import ActivityViewer from '@/site/professional/components/drawer/ActivityViewer.vue';

export default {
  components: {
    'activity-viewer': ActivityViewer,
  },
  data() {
    const now = new Date();

    return {
      times: [],
      activities: [],

      total: 10,
      currentPage: 1,
      limit: 100,
      editBoundary: parseInt(now.getTime() / 1000, 10) - 86400,

      proSearch: false,
      proFilter: undefined,
    };
  },
  computed: {
    showActivities() {
      const startIdx = (this.currentPage - 1) * this.limit;
      const endIdx = this.currentPage * this.limit;
      return this.activities.slice(startIdx, endIdx);
    },
    showedTotal() {
      return this.filteredList.length;
    },
    filteredList() {
      return this.activities.filter((a) => a.professional?.id === this.proFilter);
    },
  },
  methods: {
    editProSearch() {
      this.proSearch = true;
      this.$nextTick(() => {
        this.$refs.proPicker.$emit('focus');
      });
    },
    setProFilter(p) {
      if (p === undefined) {
        this.proSearch = false;
        this.proFilter = undefined;
        return;
      }
      this.proFilter = p?.id;
      this.currentPage = 1;
    },
    execCommand(cmd, activity) {
      console.log({ cmd, activity });
    },
    getFilter() {
      if (!this.times || this.times.length === 0) {
        this.times = this.getDefaultTime();
      }
      const [start, end] = this.times;
      end.setHours(23, 59, 59, 999);
      const filter = {
        start_at: parseInt(start.getTime() / 1000, 10),
        end_at: parseInt(end.getTime() / 1000, 10),
      };
      return filter;
    },
    changeDate() {
      this.page = 1;
      this.$execWithLoading(async () => {
        await this.loadActivities();
      });
    },
    loadData() {
      this.$execWithLoading(async () => {
        await this.loadActivities();
      });
    },
    async loadActivities() {
      const rsp = await getActivities(this.getFilter());
      this.activities = rsp;
    },
    getDefaultTime() {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      const start = new Date(now.getTime());
      const end = new Date(now.getTime() + 7 * 86400 * 1000);
      end.setHours(0, 0, 0, 0);
      return [start, end];
    },
    showActivity(data) {
      console.log(data);
      this.$refs.activityViewer.$emit('show', data);
    },
  },
  mounted() {
    this.times = this.getDefaultTime();
    setTimeout(() => {
      this.loadData();
    }, 10);
  },
};
</script>

<style lang="scss" scoped>
.activity-page {
  position: relative;
  padding: 30px 0;
  .search-bar {
    padding: 0 30px;
    .filter {
      .column {
        .title {
          font-size: 12px;
        }
        .date-range-input {
          width: 440px;
        }
        .place-input {
          width: 300px;
        }
      }
    }
  }
  .table {
    flex: 1;
  }
  .pagination {
    flex: 0 0 auto;
  }
  .empty {
    padding: 0 30px;
    margin-top: 20px;
  }
}
</style>

<style lang="scss">
@import '@/assets/style/variables.scss';

.activity-page {
  & > .table {
    margin: 0 30px;
    overflow: hidden;
    .td-tags {
      .cell {
        display: flex;
        gap: 6px;
        flex-wrap: wrap;
      }
    }
    .td-name {
      color: $primary-color;
    }
  }

  // hack
  .el-table {
    border: 1px solid #EBEEF5;
    border-bottom: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    .el-table__header {
      thead {
        tr {
          th {
            background: #E6F4F4;
            border-left: 1px solid #EBEEF5;
          }
        }
      }
    }
    .el-table__body {
      tbody {
        tr {
          td {
            border-left: 1px solid #EBEEF5;
          }
        }
      }
    }

    // handle scroll
    .el-table__header-wrapper {
      flex: 0 0 auto;
    }
    .el-table__body-wrapper {
      flex: 1;
      overflow: overlay;
    }
  }
  .dropdown-button {
    padding: 7px;
  }
  .cancel-text {
    pointer-events: none;
    color: $text-placeholder-color;
  }
}
</style>
